<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Edit Tickets</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="md:col-9 sm:col-12 comp-grid" >
                        <div :class="{ 'card': !isSubPage }" class="">
                            <div >
                                <template v-if="pageReady">
                                    <div class="grid">
                                        <div class="col-12">
                                            <form ref="observer"  tag="form" @submit.prevent="submitForm()">
                                                <!--[form-content-start]-->
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Requestby 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlrequestby" v-model.trim="formData.requestby"  label="Requestby" type="text" placeholder="Enter Requestby"      
                                                                class=" w-full" :class="getErrorClass('requestby')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('requestby')" class="p-error">{{ getFieldError('requestby') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Client 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlclient" v-model.trim="formData.client"  label="Client" type="text" placeholder="Enter Client"      
                                                                class=" w-full" :class="getErrorClass('client')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('client')" class="p-error">{{ getFieldError('client') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Priority 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlpriority" v-model.trim="formData.priority"  label="Priority" type="number" placeholder="Enter Priority"   step="any"    
                                                                class=" w-full" :class="getErrorClass('priority')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('priority')" class="p-error">{{ getFieldError('priority') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Request 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlrequest" v-model.trim="formData.request"  label="Request" type="text" placeholder="Enter Request"      
                                                                class=" w-full" :class="getErrorClass('request')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('request')" class="p-error">{{ getFieldError('request') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Estimate 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlestimate" v-model.trim="formData.estimate"  label="Estimate" type="number" placeholder="Enter Estimate"   step="any"    
                                                                class=" w-full" :class="getErrorClass('estimate')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('estimate')" class="p-error">{{ getFieldError('estimate') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Actual 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlactual" v-model.trim="formData.actual"  label="Actual" type="number" placeholder="Enter Actual"   step="any"    
                                                                class=" w-full" :class="getErrorClass('actual')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('actual')" class="p-error">{{ getFieldError('actual') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Authorize 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlauthorize" v-model.trim="formData.authorize"  label="Authorize" type="text" placeholder="Enter Authorize"      
                                                                class=" w-full" :class="getErrorClass('authorize')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('authorize')" class="p-error">{{ getFieldError('authorize') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Delivered 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrldelivered" v-model.trim="formData.delivered"  label="Delivered" type="text" placeholder="Enter Delivered"      
                                                                class=" w-full" :class="getErrorClass('delivered')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('delivered')" class="p-error">{{ getFieldError('delivered') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Starttime 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <Calendar :showButtonBar="true" :showTime="true" dateFormat="yy-mm-dd" hourFormat="24" v-model="formData.starttime" :showIcon="true" class="w-full" :class="getErrorClass('starttime')"     mask="yy-MM-DD HH:mm"     />
                                                                <small v-if="isFieldValid('starttime')" class="p-error">{{ getFieldError('starttime') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Stoptime 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <Calendar :showButtonBar="true" :showTime="true" dateFormat="yy-mm-dd" hourFormat="24" v-model="formData.stoptime" :showIcon="true" class="w-full" :class="getErrorClass('stoptime')"     mask="yy-MM-DD HH:mm"     />
                                                                <small v-if="isFieldValid('stoptime')" class="p-error">{{ getFieldError('stoptime') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Done 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrldone" v-model.trim="formData.done"  label="Done" type="text" placeholder="Enter Done"      
                                                                class=" w-full" :class="getErrorClass('done')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('done')" class="p-error">{{ getFieldError('done') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Amount 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlamount" v-model.trim="formData.amount"  label="Amount" type="number" placeholder="Enter Amount"   step="0.1"    
                                                                class=" w-full" :class="getErrorClass('amount')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('amount')" class="p-error">{{ getFieldError('amount') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Paidref 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlpaidref" v-model.trim="formData.paidref"  label="Paidref" type="text" placeholder="Enter Paidref"      
                                                                class=" w-full" :class="getErrorClass('paidref')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('paidref')" class="p-error">{{ getFieldError('paidref') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Owner *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlowner" v-model.trim="formData.owner"  label="Owner" type="text" placeholder="Enter Owner"      
                                                                class=" w-full" :class="getErrorClass('owner')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('owner')" class="p-error">{{ getFieldError('owner') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--[form-content-end]-->
                                                <div v-if="showSubmitButton" class="text-center my-3">
                                                    <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="loading">
                                    <div class="p-3 text-center">
                                        <ProgressSpinner style="width:50px;height:50px" />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required, numeric, } from '@/services/validators';
	import { useApp } from '@/composables/app.js';
	import { useEditPage } from '@/composables/editpage.js';
	const props = defineProps({
		id: [String, Number],
		pageName: {
			type: String,
			default: 'tickets',
		},
		routeName: {
			type: String,
			default: 'ticketsedit',
		},
		pagePath: {
			type : String,
			default : 'tickets/edit',
		},
		apiPath: {
			type: String,
			default: 'tickets/edit',
		},
		submitButtonLabel: {
			type: String,
			default: "Update",
		},
		msgAfterUpdate: {
			type: String,
			default: "Record updated successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	const app = useApp();
	const formDefaultValues = {
		requestby: "", 
		client: "", 
		priority: "", 
		request: "", 
		estimate: "", 
		actual: "", 
		authorize: "", 
		delivered: "", 
		starttime: "", 
		stoptime: "", 
		done: "", 
		amount: "", 
		paidref: "", 
		owner: "FASTPORT", 
	}
	const formData = reactive({ ...formDefaultValues });
	function onFormSubmited(response) {
		app.flashMsg(props.msgAfterUpdate);
		if(props.redirect) app.navigateTo(`/tickets`);
	}
	const rules = computed(() => {
		return {
			requestby: {  },
			client: {  },
			priority: { numeric },
			request: {  },
			estimate: { numeric },
			actual: { numeric },
			authorize: {  },
			delivered: {  },
			starttime: {  },
			stoptime: {  },
			done: {  },
			amount: { numeric },
			paidref: {  },
			owner: { required }
		}
	});
	const v$ = useVuelidate(rules, formData); //form validation
	const page = useEditPage(props, formData, v$, onFormSubmited);
	//page state
	const {
		submitted, // form api submitted state - Boolean
		saving, // form api submiting state - Boolean
		loading, // form data loading state - Boolean
		pageReady, // if api data loaded successfully
	} = toRefs(page.state);
	//page computed propties
	const {
		apiUrl, // page current api path
		currentRecord, // current page record  - Object
	} = page.computedProps
	//page methods
	const { 
		load, // get editable form data from api
		submitForm, // submit form data to api
		getErrorClass, // return error class if field is invalid- args(fieldname)
		getFieldError, //  get validation error message - args(fieldname)
		isFieldValid, // check if field is validated - args(fieldname)
		 // map api datasource  to Select options label-value
	} = page.methods;
	onMounted(()=>{
		const pageTitle = "Edit Tickets";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>
